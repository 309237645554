import * as microsoftTeams from "@microsoft/teams-js";

import Constants from "../misc/WegoConstants";
import WegoAuth from "../auth/WegoAuth";
import WegoAuthUser from "../auth/WegoAuthUser";
import WegoConfiguration from "../WegoConfiguration";
import { WegoContextType } from "./WegoContext";

/*
** This service is ONLY used to support scenarios inside Teams.
** This is a helper class to keep code out of WegoTeamsHost.
*/
export default class TeamsContextProvider {
    appId: string;
    authProvider: WegoAuth
    config: WegoConfiguration;

    constructor(appId: string, config: WegoConfiguration) {
        this.appId = appId;
        this.config = config;
        this.authProvider = new WegoAuth(true, config);
    }

    getTeamsContext(): Promise<WegoContextType> {
        return new Promise((resolve, reject) => {
            let hasUnhandledFailure = true;

            this.authProvider.getUser()
                .then((user: WegoAuthUser) => {
                    microsoftTeams.getContext((teamsContext) => {
                        hasUnhandledFailure = false;

                        const contextValue: WegoContextType = {
                            tenantId: teamsContext.tid!, // ???
                            appId: this.appId,
                            meetingId: teamsContext.meetingId!,
                            userId: user.userId,
                            userPrincipalName: user.userPrincipalName,
                            teamsMode: true,
                            teamsTheme: teamsContext.theme,
                            teamsFrameContext: teamsContext.frameContext,
                            configuration: this.config
                        };
                        resolve(contextValue);
                    });
                })
                .catch(error => {
                    hasUnhandledFailure = false;
                    reject(`Error getting Teams context: ${error}`);
                });

            setTimeout(() => {
                if (hasUnhandledFailure) {
                    reject(`Error getting Teams context: Timeout.`);
                }
            }, Constants.Service.timeout);
        });
    }
}