
import React from "react";
import WegoConfiguration from "../WegoConfiguration";

export const WegoContext = React.createContext<WegoContextType | null>(null);

export class WegoContextType {
    appId!: string;
    tenantId!: string;
    meetingId!: string;
    userId!: string;
    userPrincipalName!: string;
    teamsMode!: boolean;
    teamsTheme?: string;
    teamsFrameContext?: string;
    configuration!: WegoConfiguration;
}